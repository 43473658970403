<template>
  <div class="container-about">
    <div class="header">
      <v-icon color="secondary" @click="$router.go(-1)">close</v-icon>
    </div>

    <img class="image" src="@/assets/background_about.jpeg" alt="" />

    <div class="body">
      <p class="title">Sobre</p>

      <p class="subtext">
        O Museu Paranaense (MUPA) é uma instituição cultural pública, fundada em
        25 de setembro de 1876. Ele é o terceiro museu mais antigo do Brasil e a
        primeira entidade científica do Paraná. Com um acervo de aproximadamente
        800 mil objetos, as coleções salvaguardadas no MUPA figuram como uma das
        mais significativas do Brasil e América Latina. O Museu Paranaense
        desenvolve pesquisas nas áreas de Arqueologia, Antropologia e História,
        apresenta ao público exposições de curta e longa duração e promove um
        amplo conjunto de atividades culturais.
      </p>

      <p class="subtext">
        O <strong>Jardim do MUPA</strong> é um Web App desenvolvido pelo Museu
        Paranaense que tem por objetivo fornecer ao visitante informações sobre
        as plantas, as abelhas e as esculturas presentes na área externa, por
        meio de conteúdos exclusivos que relacionam as áreas de Botânica e
        Entomologia com as disciplinas de pesquisa do Museu. O visitante pode
        acessar o conteúdo gratuitamente por meio do site ou QR Code localizado
        no jardim. O Web App <strong>Jardim do MUPA</strong> foi lançado em
        comemoração aos 145 anos do Museu Paranaense, em 2021.
      </p>
    </div>
  </div>
</template>

<script>
export default {
  name: "About",
  metaInfo() {
    return {
      title: "Sobre",
      meta: [
        {
          vmid: "og:title",
          name: "og:title",
          content: "Sobre"
        },
        {
          vmid: "og:url",
          name: "og:url",
          content: `https://mupajardim.com/sobre`
        },
      ],
    };
  },
};
</script>

<style lang="scss">
.container-about {
  height: 100vh !important;

  position: relative;

  overflow-y: scroll;

  .body {
    padding: 24px 34px;
  }

  .header {
    width: 100%;
    position: fixed;

    button {
      background: #fff;

      padding: 5px;
      border-radius: 50%;
    }

    padding: 22px;

    display: flex;
    justify-content: flex-end;
  }

  .image {
    width: 100%;
    /* height: 230px; */

    object-fit: cover;
  }

  .title {
    font-weight: 400;
    font-size: 24px;
    line-height: 30px;

    /* margin-top: 20px; */
    margin-bottom: 2rem !important;
  }

  .subtext {
    font-size: 16px;
    line-height: 1.3;
    display: block;

    margin-bottom: 2rem !important;
    text-align: justify;
  }
}
</style>
