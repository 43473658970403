import instance from './base.service';

class PointsService {
  getAll() {
    return instance.get(`api/points.json`)
  }

  show(id) {
    return instance.get(`api/points/${id}.json`)
  }
}

export default new PointsService();
