<template>
  <div class="container-details-point">
    <div class="content" v-if="point" :style="background">
      <div class="header" style="z-index: 10">
        <v-icon @click="$router.go(-1)">close</v-icon>
      </div>

      <LazyYoutube
        maxWidth="100%"
        :showTitle="false"
        :autoplay="true"
        v-if="point.video_url"
        :src="point.video_url"
      ></LazyYoutube>

      <img v-else class="image" :src="point.image_url" alt="" />

      <v-list v-if="point.events.length > 0" :color="point.session.color" dark>
        <v-subheader>Eventos</v-subheader>
        <v-list-item-group>
          <v-list-item v-for="events in point.events" :key="events.id">
            <v-list-item-avatar>
              <v-icon>event</v-icon>
            </v-list-item-avatar>

            <v-list-item-content @click="getEvent(events.id)">
              <v-list-item-title v-text="events.title" />
              <v-list-item-subtitle>
                {{
                  $moment(new Date(events.start_date)).format(
                    "DD/MM/YYYY - HH:mm"
                  )
                }}
                até
                {{
                  $moment(new Date(events.end_date)).format("DD/MM/YYYY, HH:mm")
                }}
              </v-list-item-subtitle>
            </v-list-item-content>
          </v-list-item>
        </v-list-item-group>
      </v-list>

      <div class="informations">
        <p class="title-details">{{ point.title }}</p>

        <p class="subtitle-details">{{ point.scientific_name }}</p>

        <div class="full-text" v-html="point.full_text" />
      </div>
    </div>

    <v-skeleton-loader
      v-if="!point"
      type="table-heading, image, article, list-item-three-line, card-heading"
    ></v-skeleton-loader>
  </div>
</template>

<script>
import PointsService from "@/services/points.service";
import EventsService from "@/services/events.service";

export default {
  name: "DetailsPoint",
  metaInfo() {
    return {
      title: this.point.title && this.point.title,
      meta: [
        { 
          vmid: "og:title",
          name: "og:title",
          content: this.point.title
        },
        {
          vmid: "og:url",
          name: "og:url",
          content: `https://mupajardim.com/${this.slug}`
        },
        {
          vmid: "og:image",
          name: "og:image",
          content:
            this.point.image_url ||
            "https://mupajardim.com/android-chrome-512x512.png",
        },
      ],
    };
  },
  data() {
    return {
      point: {},
    };
  },
  computed: {
    id() {
      return this.$route.params.id;
    },
    background() {
      return `background-color: ${this.point.session.color}`;
    },
  },
  mounted() {
    this.initialize();
  },
  watch: {
    company: function () {
      this.initialize();
    },
  },
  methods: {
    async initialize() {
      try {
        const response = await PointsService.show(this.id);

        // console.log(response.data);

        this.point = response.data;
      } catch (error) {
        this.$router.push("/");

        console.error(error);
      }
    },
    async getEvent(id) {
      try {
        await EventsService.show(id);
      } catch (error) {
        this.$router.go(-1);

        console.error(error);
      }
    },
  },
};
</script>

<style lang="scss">
.container-details-point {
  .content {
    height: 100vh;
    width: 100%;

    position: relative;

    overflow-y: scroll;

    padding-bottom: 30px;

    .header {
      position: fixed;

      width: 100%;

      button {
        background: #fff;

        padding: 5px;
        border-radius: 50%;
      }

      padding: 22px;

      display: flex;
      justify-content: flex-end;
    }

    .image {
      width: 100%;
      height: 230px;

      object-fit: cover;
    }

    .informations {
      padding: 0px 20px;

      .title-details {
        font-weight: 400;
        font-size: 24px;
        line-height: 30px;

        color: #ffffff;

        margin-top: 28px;
      }

      .subtitle-details {
        font-weight: 400;
        font-style: italic;
        font-size: 16px;
        line-height: 20px;

        // margin-top: 6px;

        color: #fff;
      }

      .full-text {
        margin-top: 12px;

        img {
          width: 100%;
        }

        p {
          color: #fff;
          background-color: transparent;
          line-height: 1.3;
          margin-bottom: 1rem !important;
        }

        ul {
          list-style: none;
          padding: 0;
          color: #fff;
          margin-bottom: 1rem;
        }
      }

      .v-list {
        .v-subheader,
        .v-list-item {
          padding: 0;
        }
      }
    }
  }
}
</style>
