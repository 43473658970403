import Vue from 'vue'
import VueRouter from 'vue-router'

import Map from '../views/Map';
import About from '../views/About';
import DetailsPoint from '../views/DetailsPoint';

Vue.use(VueRouter)

const routes = [
  {
    path: '/',
    name: 'Map',
    component: Map
  },
  {
    path: '/sobre',
    name: 'About',
    component: About
  },
  {
    path: '/:id',
    name: 'DetailsPoint',
    component: DetailsPoint
  },
]

// eslint-disable-next-line no-new
const router = new VueRouter({
  mode: 'history',
  base: process.env.BASE_URL,
  routes
})

export default router
