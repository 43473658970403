import Vue from 'vue'
import App from './App.vue'
import router from './router'

import "./stylesheets/main.scss";

import vuetify from './plugins/vuetify'
import '@mdi/font/css/materialdesignicons.css'

import moment from 'moment';
import 'moment/locale/pt-br'

import VueMeta from 'vue-meta';

Vue.use(VueMeta, {
  refreshOnceOnNavigation: true
});

import LazyTube from "vue-lazytube";
Vue.use(LazyTube);

Vue.use(require('vue-moment'), {
  moment
})

Vue.config.productionTip = false

new Vue({
  vuetify,
  router,
  render: h => h(App)
}).$mount('#app')
