<template>
  <div class="container-map">
    <div id="map"></div>

    <map-svg id="map_svg" />

    <v-snackbar
      :timeout="-1"
      :value="true"
      v-if="viewPoint"
      fixed
      bottom
      color="#fff"
      center
      dark
      @click.native="$router.push(`/${viewPoint.slug}`)"
    >
      <div class="card-icon" :style="backgroundToSnackbar">
        <img
          v-if="viewPoint && viewPoint.session && viewPoint.session.image_url"
          :src="viewPoint.session.image_url"
          alt=""
        />
      </div>

      <p>{{ viewPoint.title }}</p>

      <v-icon color="#434343">chevron_right</v-icon>
    </v-snackbar>
  </div>
</template>

<script>
import PointsService from "@/services/points.service";

import mapSvg from "@/components/mapSvg";

import "leaflet/dist/leaflet.css";
import leaflet from "leaflet";
import "leaflet.markercluster";
import "leaflet.markercluster/dist/MarkerCluster.css";
import "leaflet.markercluster/dist/MarkerCluster.Default.css";

export default {
  name: "page-map",
  metaInfo() {
    return {
      title: "Mapa",
    };
  },
  data: () => ({
    map: null,
    points: [],
    markers: [],
    viewPoint: null,
  }),
  components: {
    mapSvg,
  },
  mounted() {
    this.map = leaflet.map("map", {
      center: this.bounds.getCenter(),
      maxBoundsViscosity: 0.5,
      zoom: 1,
      maxZoom: 7,
      minZoom: 1,
      maxBounds: this.bounds,
      zoomControl: false,
      attributionControl: false,
    });

    leaflet.svgOverlay(this.mapsvg, this.bounds).addTo(this.map);

    this.getPoints();
  },
  computed: {
    mapsvg() {
      var svgElement = document.getElementById("map_svg");

      return svgElement;
    },
    bounds() {
      return leaflet.latLngBounds(
        leaflet.latLng(0, 0),
        leaflet.latLng(720, 360)
      );
    },
    backgroundToSnackbar() {
      return `background-color: ${this.viewPoint.session.color}`;
    },
  },
  methods: {
    async getPoints() {
      try {
        const response = await PointsService.getAll();

        this.points = response.data;

        var markers = leaflet.markerClusterGroup({
          showCoverageOnHover: false,
          removeOutsideVisibleBounds: true,
          disableClusteringAtZoom: 4,
          spiderfyOnMaxZoom: false,
        });

        this.markers = response.data.forEach((point) => {
          var myIcon = new leaflet.divIcon({
            className: "icon-marker",
            iconSize: [30, 30],
            html: `<div class="marker" style="background: ${point.session.color}"></div>`,
          });

          return markers.addLayer(
            leaflet
              .marker([point.latitude, point.longitude], {
                icon: myIcon,
              })
              .on("click", this.getPoint)
          );
          // .addTo(this.map)
        });

        this.points = response.data;
        this.map.addLayer(markers);
        // markers.addTo(this.map);
      } catch (error) {
        console.error(error);
      }
    },
    getPoint(e) {
      const point = this.points.find(
        (point) =>
          point.latitude == e.latlng.lat && point.longitude == e.latlng.lng
      );

      this.viewPoint = point;
    },
  },
};
</script>

<style lang="scss">
.container-map {
  width: 100%;

  #map {
    width: 100%;
    height: calc(100vh - 96px);
    display: block;

    background-color: #fff;
  }

  .v-snack {
    position: fixed;
    z-index: 999;
    bottom: 10px;

    .v-sheet.v-snack__wrapper {
      border-radius: 8px;
    }

    .card-icon {
      position: absolute;

      border-radius: 8px 0px 0px 8px;

      left: 0;

      height: 100%;
      width: 84px;

      display: flex;
      align-items: center;
      justify-content: center;

      img {
        width: 64px;
        height: auto;
        max-height: 64px;
      }
    }

    p {
      font-weight: 400;
      font-size: 16px;
      color: #242424;
      margin-bottom: 0;
    }

    .v-snack__wrapper {
      @media (max-width: 350px) {
        min-width: 95%;
      }
    }

    .v-snack__content {
      display: flex;
      align-items: center;
      justify-content: space-between;

      margin-left: 84px;

      cursor: pointer;
      height: 72px;
    }
  }

  .icon-marker {
    width: 30px;
    height: 30px;

    .marker {
      width: 100%;
      height: 100%;

      opacity: 0.9;

      border-radius: 50%;
    }
  }

  .icon-cluster {
    div {
      display: flex;
      justify-content: center;
      align-items: center;
      background: rgba(0, 0, 0, 0.1);

      box-shadow: 0px 0px 5px #999;
      border: 1px solid rgba(0, 0, 0, 0.3);
      border-radius: 25px;
      font-weight: bold;
    }
  }
}
</style>