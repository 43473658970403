<template>
  <v-app>
    <div :class="['splash-screen', { hidden: isSplashHidden }]"></div>
    <!-- :logo="logo" -->
    <v-toolbar v-if="pages_shows.includes(route)">
      <v-app-bar-nav-icon to="/sobre">
        <img src="@/assets/icon-info.svg" alt="" />
      </v-app-bar-nav-icon>

      <v-spacer></v-spacer>
      <div class="logo" style="padding: 20px 10px">
        <img
          src="@/assets/mupa-svg.svg"
          style="width: 64px; height: auto"
          alt="loco mupa"
        />
      </div>
    </v-toolbar>

    <v-main>
      <transition
        class="card-transition"
        name="fade"
        mode="out-in"
        @beforeLeave="beforeLeave"
        @enter="enter"
        @afterEnter="afterEnter"
      >
        <router-view />
      </transition>
    </v-main>
  </v-app>
</template>

<script>
import { Plugins, StatusBarStyle } from "@capacitor/core";
const { StatusBar } = Plugins;
export default {
  name: "App",
  components: {},
  metaInfo() {
    return {
      // title: 'dashboard',
      titleTemplate: "%s | Jardim do MUPA",
      htmlAttrs: {
        lang: "en",
        amp: false,
      },
      meta: [
        {
          name: "description",
          content: `O Jardim do MUPA é um Web App desenvolvido pelo Museu Paranaense que tem por objetivo fornecer ao visitante informações sobre as plantas, as abelhas e as esculturas presentes na área externa, por meio de conteúdos exclusivos que relacionam as áreas de Botânica e Entomologia com as disciplinas de pesquisa do Museu.`,
        },
        { property: "og:title", content: "Jardim do MUPA" },
        { property: "og:site_name", content: "Jardim do MUPA" },
        {
          property: "og:description",
          content: `O Jardim do MUPA é um Web App desenvolvido pelo Museu Paranaense que tem por objetivo fornecer ao visitante informações sobre as plantas, as abelhas e as esculturas presentes na área externa, por meio de conteúdos exclusivos que relacionam as áreas de Botânica e Entomologia com as disciplinas de pesquisa do Museu.`,
        },
        { property: "og:type", content: "website" },
        { property: "og:url", content: "https://mupajardim.com" },
        {
          property: "og:image",
          content: "https://mupajardim.com/android-chrome-512x512.png",
        },
      ],
    };
  },
  data: () => ({
    pages_shows: ["Map"],
    isSplashHidden: false,
  }),
  computed: {
    route() {
      return this.$route.name;
    },
  },
  methods: {
    beforeLeave(element) {
      this.prevHeight = getComputedStyle(element).height;
    },
    enter(element) {
      const { height } = getComputedStyle(element);
      element.style.height = this.prevHeight;
      setTimeout(() => {
        element.style.height = height;
      });
    },
    afterEnter(element) {
      element.style.height = "auto";
    },
  },
  async mounted() {
    try {
      await StatusBar.setStyle({ style: StatusBarStyle.Light });
    } catch (_) {
      console.log(_);
    }
    setTimeout(() => {
      this.isSplashHidden = true;
    }, 4100);
  },
};
</script>

<style lang="scss">
.v-toolbar {
  z-index: 999 !important;
}

.fade-enter-active,
.fade-leave-active {
  transition-duration: 0.2s;
  transition-property: height, opacity;
  transition-timing-function: ease;
  overflow: hidden;
}
.fade-enter,
.fade-leave-active {
  opacity: 0;
}
.fade-transform-enter-active,
.fade-transform-leave-active {
  transition: all 0.2s;
}
.fade-transform-enter {
  opacity: 0;
  -webkit-transform: translateX(-30px);
  transform: translateX(-30px);
}
.fade-transform-leave-to {
  opacity: 0;
  -webkit-transform: translateX(30px);
  transform: translateX(30px);
}

@keyframes fadeOutSplash {
  0% {
    opacity: 1;
  }
  100% {
    opacity: 0;
    display: none;
  }
}

.splash-screen {
  background: white url(/splash.gif) no-repeat center center;
  width: 100%;
  height: 100%;
  position: fixed;
  top: 0;
  left: 0;
  z-index: 9999;
  background-size: cover;
  animation: fadeOutSplash;
  animation-delay: 3.5s;
  animation-duration: 0.5s;
  animation-fill-mode: forwards;

  &.hidden {
    display: none;
  }

  @media screen and (min-width: 600px) {
    background-size: contain;
  }
}

.ql-size-small {
  font-size: 0.75rem;
}
</style>

